import React, { useEffect } from "react";
import {
  AllActiveStatusUserType,
  GetActiveRole,
  GetAllActiveClient,
  GetUserRoleMapById,
} from "../../../axios";
import { Button, FormDropDown, FormTextInput } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const UserForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeRoleData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  // console.log("ININT",initialValue)
  const createUserheader = Strings["UserManagement.CreateUserHeader"],
    editUserheader = Strings["UserManagement.editUserHeader"],
    userNameLabel = Strings["UserManagement.createUserNameLable"],
    userNameplaceholder = Strings["UserManagement.createUserNamePlaceHolder"],
    userNameCustomError =
      Strings["UserManagement.createUserNamecustomlineerror"],
    companyLabel = Strings["UserManagement.createCompanyLable"],
    companyCustomError = Strings["UserManagement.createCompanyCustomLineError"],
    userEmailLabel = Strings["UserManagement.createUserEmailLable"],
    userEmailplaceholder = Strings["UserManagement.createUserEmailPlaceHolder"],
    userEmailCustomError =
      Strings["UserManagement.createUserEmailcustomlineerror"],
    userPhoneNumberLabel = Strings["UserManagement.createUserPhoneNumberLable"],
    userPhoneNumberPlaceholder =
      Strings["UserManagement.createUserPhoneNumberPlaceHolder"],
    userPhoneNumberCustomError =
      Strings["UserManagement.createUserPhoneNumbercustomlineerror"],
    userTypeLabel = Strings["UserManagement.createUserTypeLable"],
    userTypePlaceholder = Strings["UserManagement.createUserTypePlaceHolder"],
    userTypeCustomError =
      Strings["UserManagement.createUserTypecustomlineerror"],
    saveButton = Strings["UserManagement.createUserSaveButton"],
    cancelButton = Strings["UserManagement.createUserCancelButton"],
    updateButton = Strings["UserManagement.createUserUpdateButton"],
    selctRoleHeader =
      Strings["UserRoleMapping.createUserRoleMappingSelectRoleHeader"],
    companySelectText = Strings["UserManagement.companySelectText"];

  const [roles, setRoles] = React.useState([]);

  const [userName, setUserName] = React.useState({
    value: initialValue ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [emailAddress, setEmailAddress] = React.useState({
    value: initialValue.email ? initialValue.email : "",
    error: false,
    success: false,
  });

  const [phoneNumber, setphoneNumber] = React.useState({
    value: initialValue.phone ? initialValue.phone : "",
    error: false,
    success: false,
  });

  const [userType, setUserType] = React.useState({
    value: initialValue.user_type ? initialValue.user_type.id : "",
    error: false,
    success: false,
  });
 
  

  const [company, setCompany] = React.useState({
    value: initialValue.company ? initialValue.company.id : "",
    error: false,
    success: false,
  });

  const [userTypeDropdown, SetUserTypeDropdown] = React.useState([]);
  const [companyTypeDropdown, SetCompanyTypeDropdown] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
 
  const [tempRoles, setTempRoles] = React.useState([]);

  useEffect(() => {
    getAllActiveCompanyType();
    getAllActiveUserType();
  }, []);

  const getAllActiveCompanyType = () => {
    setLoader(true);
    GetAllActiveClient()
      .then((res) => {
        setLoader(false);
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        SetCompanyTypeDropdown(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  const getAllActiveUserType = () => {
    setLoader(true);
    AllActiveStatusUserType()
      .then((res) => {
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.user_type,
        }));
        setLoader(false);
        SetUserTypeDropdown(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    setLoader(true);
    GetActiveRole()
      .then((res) => {
        setLoader(false);
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          functionName: elm.name,
          checked: false,
        }));
        setRoles(result);
        setTempRoles(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    if (actionType === "edit") {
      setLoader(true);
      let arr = [];
      const checkArr = { checked: true };
      for (let i = 0; i < arr.length; i++) {
        arr[i] = Object.assign(arr[i], checkArr);
      }
      setLoader(true);
      GetUserRoleMapById(initialValue ? initialValue.authdata.id : null)
        .then((res) => {
          let item_id_list = res.data.data.roles.map((ele) => ele.id);
          const fetchResultData = roles.map((ele) => ({
            key: ele.key,
            functionName: ele.functionName,
            checked: item_id_list.includes(ele.key) ? true : false,
          }));
          // console.log("RESULTDATA",fetchResultData)
          setRoles(fetchResultData);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }, [tempRoles, actionType]);

  const handleChangeUserName = (value) => {
    setUserName(value);
  };

  const handleChangeCompany = (value) => {
    setCompany(value);
  };

  const handleChangeEmailAddress = (value) => {
    setEmailAddress(value);
  };

  const handleChangePhoneNumber = (value) => {
    setphoneNumber(value);
  };

  const handleChangeUserType = (value) => {
    setUserType(value);
  };

  // const handleChangeRole = (value) => {
  //   setUserRole(value)
  // }
  // const [userRole, setUserRole] = React.useState({
  //   value: roles.length ? functionResultTrue(roles)[0] : "",
  //   error: false,
  //   success: false,
  // });

  const handleChangeCheck = (key) => {
    const result = roles.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
          checked: false,
        };
      }
    });
    setRoles(result);
   
  };
  const functionResultTrue=(roles)=> {
    const resultArray = [];
    roles.forEach((func) => {
      if (func.checked === true) {
        resultArray.push(func.key);
      }
    });
  
    return resultArray;
  };

  const handleSaveData = () => {
    let company_value = "";
    if (userType.value === 1) {
      company_value = "";
    } else {
      company_value = company.value;
    }
    const fromData = {
      name: userName.value,
      email: emailAddress.value,
      phone: phoneNumber.value,
      company: company_value.toString(),
      user_type: userType.value.toString(),
      role_id: functionResultTrue(roles),
    };
    handleChangeRoleData(fromData);
  };
  roles.map((elm) => {
    if (elm.checked === true) {
      return elm.functionName;
    }
  });

  let isValueNOTChanged =
    userName.value === initialValue.userName &&
    company.value === initialValue.company &&
    emailAddress.value === initialValue.emailAddress &&
    phoneNumber.value === initialValue.phoneNumber &&
    userType.value === initialValue.userType;
    
  let disable =
    userName.error ||
    emailAddress.error ||
    // phoneNumber.error ||
    userType.error ||
    userName.value === "" ||
    emailAddress.value === "" ||
    // phoneNumber.value === "" ||
    userType.value === "" ||
    roles.filter((elm) => elm.checked === true).length === 0 ||
    (userName.success === false &&
      emailAddress.success === false &&
      // phoneNumber.success === false &&
      userType.success === false && 
      roles.filter((elm) => elm.checked === true).length === 0
      )  
      // || isValueNOTChanged;

  return (
    <div className="modalFormContainer">
      {loader ? <LoaderComponent /> : null}
      <h2>{actionType === "add" ? createUserheader : editUserheader}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        starProps={true}
        type="alpha"
        label={userNameLabel}
        placeholder={userNameplaceholder}
        Value={userName.value}
        onChangeText={handleChangeUserName}
        Validate={true}
        CustomErrorLine={userNameCustomError}
        editable={true}
        maxLength={30}
      />
      <FormTextInput
        labelTextStyle={{
          marginTop: "5px",
        }}
        starProps={actionType === "add" ? true : false}
        type="email"
        label={userEmailLabel}
        placeholder={userEmailplaceholder}
        Value={emailAddress.value}
        onChangeText={handleChangeEmailAddress}
        Validate={true}
        CustomErrorLine={userEmailCustomError}
        editable={actionType === "add" ? true : false}
        multiline={false}
      />

      <FormTextInput
        labelTextStyle={{
          marginTop: "5px",
        }}
        starProps={false}
        type="phone"
        label={userPhoneNumberLabel}
        placeholder={userPhoneNumberPlaceholder}
        Value={phoneNumber.value}
        onChangeText={handleChangePhoneNumber}
        Validate={false}
        CustomErrorLine={userPhoneNumberCustomError}
        editable={true}
        multiline={false}
        maxLength={12}
      />
      <FormDropDown
        labelStyle={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
        starProps={true}
        isValidate={true}
        Label={userTypeLabel}
        Options={userTypeDropdown}
        value={userType.value}
        onSelect={handleChangeUserType}
        customErrorLine={userTypeCustomError}
        selectText={userTypePlaceholder}
        searchable={false}
        disable={false}
      />
      {userType.value === 2 ? (
        <FormDropDown
          starProps={true}
          isValidate={true}
          labelStyle={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
          Label={companyLabel}
          Options={companyTypeDropdown}
          value={company.value}
          onSelect={handleChangeCompany}
          customErrorLine={companyCustomError}
          selectText={companySelectText}
          searchable={false}
          disable={false}
        />
      ) : null}
      <div style={{ display: "flex", paddingTop: "5px" }}>
        <h4 style={{ margin: "0" }}>{selctRoleHeader}</h4>
        <span style={{ color: "red", marginLeft: "4px", marginTop: "7px" }}>
          *
        </span>
      </div>

      <div className={"row"}>
        {roles.map((functionName) => {
          return (
            <div className={"col-12 col-md-3"}>
              <input
                type="radio"
                id={functionName.key}
                name="role"
                value={functionName.key}
                onChange={() => handleChangeCheck(functionName.key)}
                checked={functionName.checked}
              />
              <label htmlFor={functionName.key} className="ms-2">
                {functionName.functionName}
              </label>
            </div>
          );
        })}
      </div>
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveButton : actionType === "edit" ? updateButton : false}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : actionType === "edit" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default UserForm;
