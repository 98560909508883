/* eslint-disable no-unused-vars */
import { BlobProvider } from "@react-pdf/renderer";
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import { GetAllInvoiceById, GetAllPaymentSummeryById } from "../../../axios";
import {
  AddIcon,
  BreadCrumbs,
  DownloadIcon,
  IconButton,
  Modal,
  NodataFound,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import ModalPdf from "../../../components/common/ModalPdf";
import { CreatePaymentSummery } from "../../../forms";
import strings from "../../../translations";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import PaymentSummeryDownload from "./PaymentSummeryDownload";

const PaymentRequestSummery = () => {
  const poDataType = localStorage.getItem("directPO");
  const fetchId = useParams();
  const paramId = isDecoded(fetchId.id);
  const purchase_id = parseInt(paramId);
  const location = useLocation();
  const [projectDetails, setProjectDetails] = React.useState([]);

  const proId = location.state
    ? location.state.proId
    : location.query
    ? JSON.parse(location.query.params).proId
    : null;
  const budget_id = location.state
    ? location.state.budget_id
    : location.query
    ? JSON.parse(location.query.params).budget_id
    : null;
  const rfqId = location.state
    ? location.state.rfqId
    : location.query
    ? JSON.parse(location.query.params).rfqId
    : null;
  const invoice_id = location.state
    ? location.state.invoice_id
    : location.query
    ? JSON.parse(location.query.params).invoice_id
    : null;

  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);
  const encodedRfqId = isEncoded(rfqId);
  const encodedPurchase = isEncoded(purchase_id);

  var paths = [];
  if (poDataType == "true") {
    paths = [
      {
        name: strings["Projects"],
        path: "project",
      },
      {
        name: strings["Budgets"],
        path: `projectbudget/${encodeProId}`,
      },
      {
        name: strings["Budget Items"],
        path: `budgetitem/${encodeBudId}`,
      },
      {
        name: strings["PurchaseOrder"],
        path: `purchaseorders/${encodeBudId}`,
      },
      // {
      //   name: strings["Invoice"],
      //   path: `invoice/${encodedPurchase}`,
      // },
      {
        name: strings["Payment"],
        path: `payment/`,
      },
    ];
  } else {
    paths = [
      {
        name: strings["Projects"],
        path: "project",
      },
      {
        name: strings["Budgets"],
        path: `projectbudget/${encodeProId}`,
      },
      {
        name: strings["Budget Items"],
        path: `budgetitem/${encodeBudId}`,
      },
      {
        name: strings["Rfq"],
        path: `rfq/${encodeBudId}`,
      },
      {
        name: strings["Quotation"],
        path: `quotation/${encodedRfqId}`,
      },
      {
        name: strings["costanlysisText"],
        path: `costanalysis/${encodedRfqId}`,
      },
      {
        name: "Cost Analysis Report",
        path: `searchcostreport/${encodedRfqId}`,
      },
      {
        name: strings["PurchaseOrder"],
        path: `purchaseorders/${encodeBudId}`,
      },
      // {
      //   name: strings["Invoice"],
      //   path: `invoice/${encodedPurchase}`,
      // },
      {
        name: strings["Payment"],
        path: `payment/`,
      },
    ];
  }

  const [rows, setRows] = React.useState([]);
  const [rowes, setRowes] = React.useState([]);
  const [isLoader, setisLoader] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [invoiceSerialNo, setInvoiceSerialNumber] = React.useState("");
  const [currentType] = React.useState({});
  const [budgetData, setBudgetData] = React.useState({});
  const [totalCost, settotalCost] = React.useState();
  const [isDwnlModal, setisDwnlModal] = React.useState(false);

  const handleDwnldClose = () => {
    setisDwnlModal(false);
  };
  const handleDwnldOpen = () => {
    setisDwnlModal(true);
  };

  const selectDownloadModal = () => {
    return (
      <PaymentSummeryDownload projectDetails={projectDetails} allData={rowes} />
    );
  };

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };

  const getPaymentRequestdata = () => {
    setisLoader(true);
    // GetAllPaymentSummeryById(invoiceSerialNo[0])
    GetAllPaymentSummeryById(purchase_id)
      .then((res) => {
        let proData = {
          budget_slno:
            res.data[0]?.invoice[0]?.invoice.purchase_orders
              .purchase_order_item_details[0].budgetitem.budget.serial_number,
          budget_name:
            res.data[0]?.invoice[0]?.invoice.purchase_orders
              .purchase_order_item_details[0].budgetitem.budget.name,
          issue_date:
            res.data[0]?.invoice[0]?.invoice.purchase_orders
              .purchase_order_item_details[0].budgetitem.budget
              .budget_start_date,
          project_name:
            res.data[0]?.invoice[0]?.invoice.purchase_orders
              .purchase_order_item_details[0].budgetitem.budget.project.name,
        };
        setProjectDetails(proData);
        settotalCost(res.data[0].total_cost);
        setisLoader(false);
        const result = res.data[0]?.invoice.map((elm) => ({
          poValue:
            elm?.purchase_orders?.purchase_order_item_details[0]?.total_price,
          vendor: elm?.invoice?.purchase_orders?.vendor?.name,
          poValue: 100,
          poNumber: elm.invoice.purchase_order_serial_number,
          InvoiceNumber: elm.invoice.invoice_name,
          values: elm.invoice.total_amount,
          currencys: budgetData.currency,
          rate: budgetData.currency.rate,
        }));
        setRowes(result);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };
  React.useEffect(() => {
    getPaymentRequestdata();
  }, [invoiceSerialNo]);

  const getStatus = (row) => {
    let paidAmount = 0.00;
    if(row.invoice_payment != null){
      if(row.invoice_payment.length != 0){
        paidAmount = row.invoice_payment.reduce((acc, curr) => {
            return parseFloat(acc + parseFloat(curr.amount));
          }, 0) 
      }
      else{
        return "UNPAID"
      }
    }
    else{
      return "UNPAID"
    }
    if(parseFloat(row.total_amount) > parseFloat(paidAmount) && parseFloat(paidAmount) != 0){
       return "PARTIALLY PAID"
    }
    if(parseFloat(row.total_amount) === parseFloat(paidAmount)){
      return "PAID"
    }
  }

  const getData = () => {
    setisLoader(true);
    GetAllInvoiceById(purchase_id)
      .then((res) => {
        // console.log("******************",res.data)
        const budgetData = res.data[0];
        setBudgetData(budgetData);
        const invoiceSerialNumber = res.data[1].data.map((ele) => {
          return ele.invoice_serial_number;
        });
        setInvoiceSerialNumber(invoiceSerialNumber);
        setisLoader(false);
        const result = res.data[1].data.map((elm) => ({
          id: elm.id,
          creationDate: elm.created_on.split(" ")[0],
          poNo: elm.purchase_orders.purchase_order_serial_number,
          invoiceNo: elm.invoice_name,
          value: elm.total_amount,
          currency: budgetData.currency.name,
          rate: budgetData.currency.rate,
          convertValue: elm.total_amount * budgetData.currency.rate,
          Status: elm.invoice_status,
          checked: false,
          paymentStatus: getStatus(elm)
        }));
        let filteredData = result.filter((val) => {
          return val.paymentStatus == "UNPAID" || val.paymentStatus == 'PARTIALLY PAID';
        });
      
        setRows(filteredData);
      })
      .catch(() => {});
  };
  React.useEffect(() => {
    getData();
    // getPaymentRequestdata();
  }, []);

  const headers = [
    { label: "PO No.", key: "poNo" },
    { label: "Invoice No.", key: "invoiceNo" },
    { label: "Value", key: "value" },
    { label: "Currency", key: "currency" },
    { label: "Rate", key: "rate" },
    { label: "Convert Value", key: "convertValue" },
  ];

  const csvReport = {
    data: rows,
    headers: headers,
    filename: "payment_summery.csv",
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const selectModal = () => {
    return (
      <CreatePaymentSummery
        getPaymentRequestdata={getPaymentRequestdata}
        handleChangeRoleData={(val) => {}}
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentType : {}}
        handleModalClose={handleModalClose}
        rows={rows}
      />
    );
  };

  return (
    <div className="modalFormContainer mb-5 pb-5">
      <Helmet>
        <title>Furnish | Payment Request Summary</title>
      </Helmet>
      <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </Modal>

      <ModalPdf isOpen={isDwnlModal} onHandleClose={handleDwnldClose}>
        {selectDownloadModal()}
      </ModalPdf>

      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          alignItems: "center",
        }}
      >
        <div className="col">
          <h3 className="">Payment Request Summary</h3>
          <BreadCrumbs
            pathList={paths}
            obj={{
              proId: proId,
              budget_id: budget_id,
              rfqId: rfqId,
              invoice_id: invoice_id,
            }}
          />
        </div>
        <div>
          <span className="addButton">
            <IconButton
              onClick={() => {
                openAddModal();
                handleSelectBtn("add");
              }}
            >
              <AddIcon />
            </IconButton>
          </span>
          <span className="addButton">
            <IconButton onClick={handleDwnldOpen}>
            <DownloadIcon />
              {/* <BlobProvider
                document={
                  <PaymentSummeryDownload
                    projectDetails={projectDetails}
                    allData={rowes}
                  />
                }
              >
                {({ url }) => (
                  <a href={url} target="_blank">
                    <DownloadIcon />
                  </a>
                )}
              </BlobProvider> */}
            </IconButton>
          </span>
        </div>
      </div>
      {isLoader ? <LoaderComponent /> : null}
      {rowes.length > 0 ? (
        <div className="col-12 mt-5">
          <div className="AssetbudgetTable">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Purchase Order No</th>
                  <th scope="col">Invoice No</th>
                  <th scope="col">Value</th>
                  <th scope="col">Currency</th>
                  <th scope="col">Rate</th>
                  <th scope="col">Converted value</th>
                </tr>
              </thead>
              <tbody>
                {rowes.map((elem) => {
                  return (
                    <>
                      <tr>
                        <td>{elem.poNumber}</td>
                        <td>{elem.InvoiceNumber}</td>
                        <td>AED {parseFloat(elem.values).toFixed(2)}</td>
                        <td>{elem.currencys.name}</td>
                        <td>{elem.currencys.rate}</td>
                        <td>
                          {parseFloat(
                            `${elem.values * elem.currencys.rate}`
                          ).toFixed(2)}
                        </td>
                      </tr>
                    </>
                  );
                })}
                {/* <tr>
                  <td colSpan={5}></td>
                  <td style={{ fontWeight: "bold" }}>
                    Total Value : AED {totalCost}
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <>
          {isLoader ? (
            <LoaderComponent />
          ) : (
            <NodataFound
              onClick={() => {
                openAddModal();
                handleSelectBtn("add");
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
export default PaymentRequestSummery;
