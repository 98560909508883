/* eslint-disable no-unused-vars */
import { TablePagination } from "@mui/material";
import * as React from "react";
import swal from "sweetalert";
import {
  CreateProjectRole,
  GetAllProjectRole,
  UpdateProjectRole,
  UpdateStatusProjectRole,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  FormTextInput,
  IconButton,
  Modal,
  NodataFound,
  SearchTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { ProjectRoleForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_PROJECT_ROLE_STATUS_FUNCTION,
  CREATE_PROJECT_ROLE,
  UPDATE_PROJECT_ROLE,
} from "../../../utils/constants";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";

export default function SearchProjectRole() {
  const searchRoleTitle = Strings["ProjectRole.searchRoleTitle"],
    alertMessage = Strings["RoleManagement.AlertMessage"],
    alertTitle = Strings["RoleManagement.AlertTitle"],
    roleName = Strings["ProjectRole.roleName"],
    roleDescription = Strings["ProjectRole.roleDescription"],
    activeStatus = Strings["RoleManagement.activeStatus"],
    action = Strings["RoleManagement.action"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [statusCode, setStatusCode] = React.useState();

  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_PROJECT_ROLE)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_PROJECT_ROLE)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_PROJECT_ROLE_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);
  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["ProjectRole"],
      path: "master-configuration/searchprojectrole",
    },
  ];
  const mapableData = [
    {
      dataKey: "name",
      title: roleName,
    },
    {
      dataKey: "description",
      title: roleDescription,
    },
    // {
    //   dataKey: "is_active",
    //   title: activeStatus,
    //   isStatus: true,
    // },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };
  const user = JSON.parse(localStorage.getItem("user"));

  const createRole = (formData) => {
    setisLoader(true);
    CreateProjectRole(formData)
      .then((res) => {
        setisLoader(false);
        setRows([res.data.data, ...rows]);
        swal({
          title: "Success!",
          text: "Created Sucessfully",
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const updateRole = (formData) => {
    setisLoader(true);
    const id = currentType.id;
    UpdateProjectRole(id, formData)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          const result = rows.map((ele) => {
            if (ele.id === res.data.data.id) {
              return {
                ...ele,
                name: res.data.data.name,
                description: res.data.data.description,
              };
            } else {
              return ele;
            }
          });
          swal({
            title: "Success!",
            text: "Updated Sucessfully",
            icon: "success",
          });
          setRows(result);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <ProjectRoleForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeData={clickedBtn === "add" ? createRole : updateRole}
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentType : {}}
        handleModalClose={handleModalClose}
      />
    );
  };

  const handleChangeActiveStatus = (id, status) => {
    setStatusCode(status);
    setStatusChangeId(id);
    setAlertBox(true);
  };
  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const getAllRole = () => {
    setisLoader(true);
    GetAllProjectRole(rowsPerPage, page, searchValue.value)
      .then((res) => {
        setisLoader(false);
        setTotalData(res.data.data.total);
        // console.log(res);
        setRows(res.data.data.data);
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response);
      });
  };
  React.useEffect(() => {
    getAllRole();
  }, [rowsPerPage, page]);

  const handleChangeSuccess = () => {
    setisLoader(true);
    const sta = { status: 1 - statusCode };

    UpdateStatusProjectRole(statusChangeId, sta)
      .then((res) => {
        const result = rows.map((elem) => {
          if (elem.id === res.data.data.id) {
            return { ...elem, is_active: res.data.data.is_active };
          } else {
            return elem;
          }
        });
        setRows(result);
        setisLoader(false);
        setAlertBox(false);
      })
      .catch(() => {
        setisLoader(false);
      });
  };

  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id;
    });
    setCurrentType(result);
    handleSelectBtn("edit");
  };
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setisLoader(true);
      GetAllProjectRole(rowsPerPage, page, searchData.value)
        .then((res) => {
          setisLoader(false);
          setTotalData(res.data.data.total);
          // console.log(res);
          setRows(res.data.data.data);
        })
        .catch((e) => {
          setisLoader(false);
          setMessage(e.response);
        });
    }
    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAllRole();
  };

  function CustomTablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div style={{ flexShrink: 0, marginLeft: '1rem' }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }
  return (
    <>
      <div className="container">
        {isLoader ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="row mt-4">
          <div className="col">
            <h3> {searchRoleTitle} </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          {isAdd && (
            <div className="col-auto">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
          <div className="row">
            {alertBox && (
              <AlertMessage
                title={alertTitle}
                message={alertMessage}
                onChangeCancel={handleChangeCancel}
                onChangeSucess={handleChangeSuccess}
              />
            )}
            <div className="UserbudgetTable">
              <div className="col-12">
                <form onSubmit={handleSearchSubmit}>
                  <FormTextInput
                    type="text"
                    className="searchBarUser mb-3"
                    placeholder={`Search `}
                    Value={searchValue.value}
                    onChangeText={handleSearch}
                    inputStyle={{ border: "none" }}
                    editable
                  />
                </form>
                {rows.length !== 0 ? (
                  <>
                    <SearchTable
                      isUpdate={isUpdate}
                      isStatus={isStatus}
                      placeholder="project role name"
                      data={rows}
                      mapableData={mapableData}
                      searchProp={["name"]}
                      handleChangeActiveStatus={handleChangeActiveStatus}
                      handleChangeEdit={handleChangeEdit}
                    />
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component="div"
                      count={totalData}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={CustomTablePaginationActions}
                    />
                  </>
                ) : (
                  <>
                    {isLoader ? (
                      <LoaderComponent />
                    ) : (
                      <NodataFound
                        onClick={() => {
                          openAddModal();
                          handleSelectBtn("add");
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
