/* eslint-disable no-unused-vars */
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { IconButton, SwitchButton } from "../../components";
import { colors } from "../../configs";

const BodyData = ({
  bodyData,
  editOnClick,
  isEdit,
  isSwitch,
  isDelete,
  swichOnClick,
  deleteOnClick,
  isUpdate,
  isStatus,
}) => {
  return (
    <tr className="tablerow">
      {Object.keys(bodyData)
        .filter((data) => {
          return data !== "id";
        })
        .map((d, index) => {
          return (
            <td
              className="align-middle tabledata"
              key={Math.random() * 10000}
              style={{ wordWrap: "break-word" }}
            >
              {typeof bodyData[d] === "object"
                ? bodyData[d].comp && bodyData[d].component
                : bodyData[d]}
            </td>
          );
        })}
      {(isEdit || isSwitch || isDelete) && (
        <td>
          {isEdit && (
            <IconButton
              onClick={() => {
                editOnClick(bodyData.id);
              }}
            >
              {isUpdate && (
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              )}
            </IconButton>
          )}
          {isSwitch && (
            <SwitchButton
              checked={bodyData.activeStatus}
              onClick={() => {
                swichOnClick(bodyData.id);
              }}
            />
          )}
          {isDelete && (
            <IconButton
              onClick={() => {
                deleteOnClick(bodyData.id);
              }}
            >
              {isStatus && (
                <Tooltip title="Delete">
                  <DeleteIcon sx={{ color: colors.danger }} />
                </Tooltip>
              )}
            </IconButton>
          )}
        </td>
      )}
    </tr>
  );
};

const Table = (props) => {
  const {
    isUpdate,
    isStatus,
    theadData,
    tbodyData,
    fields,
    isEdit,
    isSwitch,
    isDelete,
    deleteOnClick = () => {},
    editOnClick = () => {},
    swichOnClick = () => {},
  } = props;

  const bodyData = () => {
    const resultArray = [];
    tbodyData.forEach((data) => {
      const result = {};
      for (let prop in data) {
        if (fields.includes(prop)) {
          result[prop] = data[prop];
        }
      }
      resultArray.push(result);
    });
    return resultArray;
  };

  const res = bodyData();
  // console.log(res);
  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            {theadData.map((head) => {
              return <th key={Math.random() * 10000}>{head.label}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {res.map((bodyData, ind) => {
            return (
              <BodyData
                isUpdate={isUpdate}
                isStatus={isStatus}
                key={Math.random() * 10000}
                bodyData={bodyData}
                editOnClick={editOnClick}
                swichOnClick={swichOnClick}
                deleteOnClick={deleteOnClick}
                isSwitch={isSwitch}
                isDelete={isDelete}
                isEdit={isEdit}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
