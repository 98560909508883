import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import { GetPaymentSummery } from "../../../axios";
import { BreadCrumbs } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import strings from "../../../translations";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import { getItemFromStorage } from "../../../utils/storage";

const PaymentSummary = () => {
  const [isLoader, setisLoader] = React.useState(false);
  const [data, setData] = React.useState();
  const [budgetData, setBudgetData] = React.useState({});
  const location = useLocation();
  let proId = location.state
    ? location.state.proId
    : location.query
    ? JSON.parse(location.query.params).proId
    : null;
  const fetchId = useParams();
  const paramId = isDecoded(fetchId.id);
  const BudgtId = parseInt(paramId);
  let budget_id = location.state ? location.state.budget_id : BudgtId;
 
  // console.log(data);
  let scope_id = location?.state ? location?.state?.scope_id : location?.query
  ? JSON.parse(location.query.params).scope_id
  : null;
  let budget_Name = location?.state ? location.state?.budget_name
    : location?.query ? JSON.parse(location.query.params).budget_name
    : null;

  
  if(scope_id == null || scope_id == undefined && budget_Name == null || budget_Name == undefined && proId == null || proId == undefined && budget_id == null || budget_id == undefined){
      const paramData = JSON.parse(getItemFromStorage("PARAMS"))
      if(paramData){
          budget_id = paramData.budget_id;
          proId = paramData.proId;
          budget_Name = paramData.budget_Name;
          scope_id =  paramData.budget_scope;
      }
  }
  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);

  function ccyFormat(num) {
    return `${parseFloat(num).toFixed(2)}`;
  }

  const paths = [
    {
      name: strings["Projects"],
      path: "project",
    },
    {
      name: strings["Budgets"],
      path: `projectbudget/${encodeProId}`,
    },
    {
      name: strings["Budget Items"],
      path: `budgetitem/${encodeBudId}`,
    },
    {
      name: strings["PurchaseOrder"],
      path: `purchaseorders/${encodeBudId}`,
    },
    {
      name: "Payment Summary",
    },
  ];

  const getPaymentSummary = () => {
    setisLoader(true);
    GetPaymentSummery(budget_id)
      .then((res) => {
        setBudgetData(res.data.data.budget);
        setData(res.data.data.invoice);
        setisLoader(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getPaymentSummary();
  }, []);

  return (
    <div className="content-wrapper p-0">
      <Helmet>
        <title>Furnish | Payment Summary</title>
      </Helmet>
      {isLoader ? <LoaderComponent /> : null}
      <div
        className="row align-items-center mt-4"
        style={{
          alignItems: "center",
        }}
      >
        <div className="col">
          <h3 className="">{"Payment Summary"}</h3>
          <BreadCrumbs
            pathList={paths}
            obj={{
              proId: proId,
              budget_id: budget_id,
              budget_name: budget_Name,
              scope_id: scope_id
            }}
          />
        </div>

        <TableContainer component={Paper} className="mt-4">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography variant="body1">Purchase Order No.</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((ele) => {
                  return (
                    <>
                      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell
                          style={{
                            backgroundColor: colors.primary,
                            color: colors.white,
                          }}
                          colSpan={4}
                          component="th"
                          scope="row"
                          align="left"
                        >
                          {ele.purchase_order_serial_number}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse in={true} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Invoice No</TableCell>
                                    <TableCell align="right">Value</TableCell>
                                    <TableCell align="right">
                                      Currency
                                    </TableCell>
                                    <TableCell align="right">Rate</TableCell>
                                    <TableCell align="right">
                                      Converted Value
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {ele.purchase_order_invoice &&
                                    ele.purchase_order_invoice.map((elem) => {
                                      if(elem.is_active == 1){
                                      return (
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            {elem.invoice_name}
                                          </TableCell>
                                          <TableCell align="right">
                                            {/* {" "} */}
                                            {parseFloat(elem.total_amount).toFixed(2)}
                                          </TableCell>
                                          <TableCell align="right">
                                            {" "}
                                            {budgetData.currency.name}
                                          </TableCell>
                                          <TableCell align="right">
                                            {" "}
                                            {budgetData.currency.rate}
                                          </TableCell>
                                          <TableCell align="right">
                                            {ccyFormat(
                                              elem.total_amount *
                                                budgetData.currency.rate
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      )};
                                    })}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default PaymentSummary;
