import IconButton from "@mui/material/IconButton";
import TablePagination from "@mui/material/TablePagination";
import * as React from "react";
import swal from "sweetalert";
import {
  CreateGroupToSubGroupMap,
  GetAllGroupToSubGroupMap,
  UpdateGroupToSubGroupMap,
  addBudgetGroupSubgroup,
  UpdateBudgetGroupSubgroup,
  GetBudgetGroupSubGroup
} from "../../../axios";
import {
  AddIcon,
  BreadCrumbs,
  FormTextInput,
  Modal,
  NodataFound,
  SearchMappingTable,
  AlertMessage
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import CreateBudgetspecificMappingGroupSubGroupForm from "../../../forms/Masters/CreateGroupSubGroupMapping/CreateBudgetSpecificGroupSubGroupMappingForm";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CREATE_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING,
  UPDATE_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING,
} from "../../../utils/constants";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";


export default function SearchGroupToSubGroupMapping(props) {
    
    const {
        budget_id,
        scope_id
    } = props
   
    // console.log("PROPS",props)
  const searchMapingScopeItemGroupTitle =
      Strings["ItemGroupSubGroupMapping.searchMapingGroupTitle"],
    action = Strings["RoleFunctionMapping.TableHead.action"],
    group = Strings["ItemGroupSubGroupMapping.TableHead.group"],
    subGroup = Strings["ItemGroupSubGroupMapping.TableHead.subGroup"],
    alertMessage = Strings["ItemGroup.DeleteMessage"],
    alertTitle = Strings["ItemCategory.AlertTitle"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [isDelete, setIsDelete] = React.useState(true);
  const [alertBox, setAlertBox] = React.useState(false);
  const [isLoader, setisLoader] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["ItemGroupSubGroupMapping"],
    },
  ];

  const mapableData = [
    // {
    //   dataKey: "scope_name",
    //   title: "Budget Scope",
    // },
    {
      dataKey: "groupname",
      title: group,
    },
    {
      dataKey: "subgroupname",
      title: subGroup,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];
  const getAllItemMap = () => {
    setLoading(true);
    // console.log({searchValue});
    GetBudgetGroupSubGroup(budget_id,rowsPerPage, page, searchValue.value)
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setRows(res.data.data);
        setTotalData(res.data.total);
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response);
      });
  };
  React.useEffect(() => {
    getAllItemMap();
  }, [rowsPerPage, page]);

  const createGroupSubGroup = (formData) => {
    formData["budget_id"] = budget_id;
    setLoading(true);
    addBudgetGroupSubgroup(formData)
      .then((res) => {
        getAllItemMap();
        setLoading(false);
        swal({
          title: "Success!",
          text: "Created Successfully",
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };

  const updateGroupSubGroup = () => {
    setLoading(true);
    UpdateBudgetGroupSubgroup(currentType.id)
      .then((res) => {
        getAllItemMap();
        setLoading(false);
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Updated Successfully",
            icon: "success",
          });
          handleModalClose();
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.message);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const selectModal = () => {
    return (
      <CreateBudgetspecificMappingGroupSubGroupForm
        errorMessage={errorMessage}
        scope_id ={scope_id}
        setMessage={setMessage}
        handleResultData={
          clickedBtn === "add" ? createGroupSubGroup : updateGroupSubGroup
        }
        actionType={clickedBtn}
        initialValue={clickedBtn === "edit" ? currentType : {}}
        handleModalClose={handleModalClose}
      />
    );
  };

  // const converter = (rows) => {
  //   let result = "";
  //   rows?.forEach((data) => {
  //     if (result.length === 0) {
  //       result = result + data.name;
  //     } else {
  //       result = result + "," + data.name;
  //     }
  //   });
  //   return result;
  // };

  // const getData = (rows) => {
  //   const result = rows?.map((data) => {
  //     return {
  //       ...data,
  //       subgroups: converter(data.subgroups),
  //     };
  //   });
  //   return result;
  // };

  // const searchTableData = getData(rows);

//   const handleChangeEdit = (id) => {
//     openAddModal();
//     const result = rows.find((row) => {
//       return (
//         row.item_group_id === id.item_group_id && row.scope_id === id.scope_id
//       );
//     });
//     setCurrentType(result);
//     handleSelectBtn("edit");
//   };

  const handleChangeDelete = (id) => {
   
    setAlertBox(true);
    const resultCategory = rows.find((res) => {
      return res.id === id;
    });
    setCurrentType(resultCategory);

  };
  
  
  
  const handleSearch = (searchData) => {
    const data = searchData.value;
    
    setSearchValue(searchData);
    if (searchData.value === "") {
      setLoading(true);
      GetBudgetGroupSubGroup(budget_id,rowsPerPage, page, searchData.value)
        .then((res) => {
          // console.log(res);
          setLoading(false);
          setRows(res.data.data);
          setTotalData(res.data.total);
        })
        .catch(() => {
          setLoading(false);
          setLoading(false);
        });
    }
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setisLoader(true);
    UpdateBudgetGroupSubgroup(currentType.id)
      .then((res) => {
        setisLoader(false);
        getAllItemMap();
        setAlertBox(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
    setAlertBox(true);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAllItemMap();
  };

  function CustomTablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div style={{ flexShrink: 0, marginLeft: '1rem' }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        {loading ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="row mt-1">
          <div className="col">
            <h3> {searchMapingScopeItemGroupTitle} </h3>
          </div>
          {isAdd && (
            <div className="col-auto">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
          <div className="row">
          {alertBox && (
              <AlertMessage
                title={alertTitle}
                message={alertMessage}
                onChangeCancel={handleChangeCancel}
                onChangeSucess={handleChangeSuccess}
              />
            )}
            <div className="col-12">
              <div className="UserbudgetTable">
                <form onSubmit={handleSearchSubmit}>
                  <FormTextInput
                    type="text"
                    className="searchBarUser mb-3"
                    placeholder={`Search `}
                    Value={searchValue.value}
                    onChangeText={handleSearch}
                    inputStyle={{ border: "none" }}
                    editable
                  />
                </form>
                {rows.length !== 0 ? (
                  <>
                    <SearchMappingTable
                      isDelete ={isDelete}
                      placeholder={"Group Name"}
                      data={rows}
                      mapableData={mapableData}
                      searchProp={["groupname"]}
                      handleChangeDelete={handleChangeDelete}
                    />
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component="div"
                      count={totalData}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={CustomTablePaginationActions}
                    />
                  </>
                ) : (
                  <>
                    {loading ? (
                      <LoaderComponent />
                    ) : (
                      <NodataFound
                        onClick={() => {
                          openAddModal();
                          handleSelectBtn("add");
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
