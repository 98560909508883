/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  CreateItemSchedule,
  DeleteItemSchedule,
  GetBudgetByID,
  GetBudgetItemByID,
  GetItemSchedule,
  UpdateBudgetItem,
  UpdateItemSchedule,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  IconButton,
  Modal,
  Table,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import {
  CreateBudgetItemForm,
  CreateItemScheduleForm,
  UploadFileItemSchedule,
} from "../../../forms";
import strings from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_ITEM_SCHEDULE_FUNCTION,
  CREATE_ITEM_SCHEDULE,
  UPDATE_ITEM_SCHEDULE,
} from "../../../utils/constants";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import "./itemschedule.css";

const SearchItemSchedulePage = () => {
  const itemScheduleHeader = strings["ItemSchedule.itemScheduleHeader"],
    alertMessage = strings["ItemSchedule.AlertMessage"],
    alertTitle = strings["ItemSchedule.AlertTitle"],
    Data = strings["ItemSchedule.table.Data"],
    PlannedEndDate = strings["ItemSchedule.table.PlannedEndDate"],
    ActualEndDate = strings["ItemSchedule.table.ActualEndDate"],
    Forecast = strings["ItemSchedule.table.Forecast"],
    Difference = strings["ItemSchedule.table.Difference"],
    Action = strings["ItemSchedule.table.Action"];

  const [itemScheduleData, setItemScheduleData] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentUserType, setCurrentUserType] = React.useState({});
  const [uid, setUid] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [itemDetail, setItemDetail] = React.useState({});
  const [userId, setUserId] = React.useState();
  const [budgetDetail, setBudgetDetail] = React.useState({});

  // console.log(itemDetail);
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_ITEM_SCHEDULE)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_ITEM_SCHEDULE)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_ITEM_SCHEDULE_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);
  const params = useParams();
  const location = useLocation();
  // console.log(location);
  const resArray = location?.state ? location?.state : null;
  // console.log(resArray);
  const budget_scope = location?.budget_scope ? location?.budget_scope : null;
  // console.log(budget_scope);
  const proId = location.state
    ? location.state[0]?.budget.project_id
    : location.query
    ? JSON.parse(location.query.params).proId
    : null;
  const fetchId = isDecoded(params.id);
  const budgetItemId = parseInt(fetchId);
  const BudId = isDecoded(fetchId.budgetID);
  const BudgtId = parseInt(BudId);
  const budget_id = location.state ? location.state[0]?.budget.id : BudgtId;
  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);

  const fields = [
    "id",
    "stage",
    "plannedStartDate",
    "plannedEndDate",
    "actualEndDate",
    "differnce",
    "forecast",
  ];

  const paths = [
    {
      name: strings["Projects"],
      path: "project",
    },
    {
      name: strings["Budgets"],
      path: `projectbudget/${encodeProId}`,
    },
    {
      name: strings["Budget Items"],
      path: `budgetitem/${encodeBudId}`,
    },
    {
      name: "Item Schedule",
      path: "itemschedule",
    },
  ];
  const head = [
    {
      id: "stage",
      label: Data,
    },
    {
      id: "plannedEndDate",
      label: PlannedEndDate,
    },
    {
      id: "forecast",
      label: Forecast,
    },
    {
      id: "actualEndDate",
      label: ActualEndDate,
    },
    {
      id: "differnce  ",
      label: Difference,
    },
    {
      id: "action  ",
      label: Action,
    },
  ];

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  const handleChangeSuccess = () => {
    setAlertBox(false);
    setLoader(true);
    DeleteItemSchedule(uid)
      .then((res) => {
        setLoader(false);
        const updateData = itemScheduleData.filter((elem) => {
          return elem.id !== res.data.data.id;
        });
        setItemScheduleData(updateData);
        setAlertBox(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const getItemSchedule = () => {
    setLoader(true);
    GetItemSchedule(budgetItemId)
      .then((res) => {
        const result = res.data.data.map((elm) => ({
          id: elm.id,
          stage: elm.stage.name,
          plannedEndDate: elm.budget_start_date.split(" ")[0],
          forecast: elm.forecast.split(" ")[0],
          actualEndDate: elm.budget_end_date.split(" ")[0],
          differnce: elm.duration,
          stage_id: elm.stage,
        }));
        setItemScheduleData(result);

        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const getBudgetItemDetails = () => {
    setLoader(true);
    GetBudgetItemByID(budgetItemId)
      .then((res) => {
        setLoader(false);
        setItemDetail(res.data.data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  React.useEffect(() => {
    getItemSchedule();
    getBudgetItemDetails();
  }, []);

  const onEditClick = (id) => {
    const result = itemScheduleData.find((res) => {
      return res.id === id;
    });
    setCurrentUserType(result);
    setUid(result.id);
    openAddModal();
    handleSelectBtn("edit");
  };
  const handleChangeDelete = (id) => {
    setAlertBox(!alertBox);
    setUid(id);
  };

  const createSchedule = (formData) => {
    setLoader(true);
    CreateItemSchedule(formData)
      .then((res) => {
        setLoader(false);
        getItemSchedule();
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Created Successfully",
            icon: "success",
          });
        }
        handleModalClose();
      })
      .catch((e) => {
        setLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const updateSchedule = (formData) => {
    UpdateItemSchedule(formData, uid)
      .then((res) => {
        getItemSchedule();
        setLoader(false);
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Updated Successfully",
            icon: "success",
          });
        }
        handleModalClose();
      })
      .catch((e) => {
        setLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const getBudgetDetails = () => {
    GetBudgetByID(budget_id)
      .then((res) => {
        setBudgetDetail(res.data.data);
      })
      .catch(() => {});
  };
  React.useEffect(() => {
    getBudgetDetails();
  }, []);
  const updateBudgetItem = (formData) => {
    setLoader(true);
    UpdateBudgetItem(formData, userId)
      .then((res) => {
        // getAlldata();
        getItemSchedule();
        getBudgetItemDetails();
        setLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const selectModal = () => {
    if (clickedBtn === "add") {
      return (
        <CreateItemScheduleForm
          budgetItemId={params.id}
          setMessage={setMessage}
          errorMessage={errorMessage}
          handleChangeFormData={
            clickedBtn === "add" ? createSchedule : updateSchedule
          }
          actionType={clickedBtn}
          initialValue={
            clickedBtn === "add" ? { itemScheduleData } : currentUserType
          }
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "Upload") {
      return (
        <UploadFileItemSchedule
          budgetItemId={budgetItemId}
          actionType={"add"}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "edit") {
      return (
        <CreateItemScheduleForm
          budgetItemId={budgetItemId}
          setMessage={setMessage}
          errorMessage={errorMessage}
          handleChangeFormData={
            clickedBtn === "add" ? createSchedule : updateSchedule
          }
          actionType={clickedBtn}
          initialValue={
            clickedBtn === "add" ? { itemScheduleData } : currentUserType
          }
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "itemEdit") {
      return (
        <CreateBudgetItemForm
          proId={proId}
          budget_id={budget_id}
          budget_scope={budget_scope}
          handleBudgetItemData={updateBudgetItem}
          actionType={clickedBtn}
          errorMessage={errorMessage}
          initialValue={currentUserType}
          handleModalClose={handleModalClose}
          rseArray={resArray}
          budgetDetail={budgetDetail}
        />
      );
    }
  };

  const handleUploadItem = (id) => {
    openAddModal();
    handleSelectBtn("Upload");
  };
  function handleActionEdit() {
    const result = resArray.find((res) => {
      return res.id === itemDetail.id;
    });
    setCurrentUserType(result);
    setUserId(result.id);
    openAddModal();
    handleSelectBtn("itemEdit");
  }
  return (
    <div className="container">
      {loader ? <LoaderComponent /> : null}
      <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </Modal>
      <div className="row mt-3">
        <div
          className="d-md-flex d-block justify-content-between"
          style={{
            alignItems: "center",
          }}
        >
          <div className="col">
            <h3>{itemScheduleHeader}</h3>
            <BreadCrumbs
              pathList={paths}
              obj={{
                proId: proId,
                budget_id: budget_id,
              }}
            />
          </div>
          {alertBox && (
            <AlertMessage
              title={alertTitle}
              message={alertMessage}
              onChangeCancel={handleChangeCancel}
              onChangeSucess={handleChangeSuccess}
            />
          )}
          <div>
            <button
              className={"budgetitembutton me-3"}
              buttonName={"Item Upload"}
              onClick={() => {
                handleUploadItem();
              }}
            >
              Item Upload
            </button>
            {isAdd && (
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            )}
          </div>
        </div>
        <div className="modalFormContainer">
          <div className="row"></div>
        </div>
        <div className="itemSchedulebudgetTable">
          <Table
            isUpdate={isUpdate}
            isStatus={isStatus}
            theadData={head}
            tbodyData={itemScheduleData}
            fields={fields}
            isDelete={true}
            isEdit={true}
            editOnClick={onEditClick}
            deleteOnClick={handleChangeDelete}
          />
        </div>

        <Box className="itemSchedulebudgetTable">
          <div className="row align-items-center">
            <div className="col-6">
              <h3>Item Details</h3>
            </div>
            <div className="col-6 text-end">
              <IconButton onClick={handleActionEdit}>
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              </IconButton>
            </div>
          </div>

          <table className="table">
            <tbody>
              <tr>
                <td>Control No.</td>
                <td>{itemDetail?.control_number}</td>
              </tr>
              <tr>
                <td>Item Name</td>
                <td>{itemDetail?.name}</td>
              </tr>
              <tr>
                <td>Item Description</td>
                <td>{itemDetail?.description}</td>
              </tr>
              <tr>
                <td>Item Group</td>
                <td>{itemDetail?.itemgroup?.group_name}</td>
              </tr>
              <tr>
                <td>Item Subgroup</td>
                <td>
                  {itemDetail?.itemSubgroup
                    ? itemDetail.itemSubgroup?.name
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Item Subgroup</td>
                <td>{itemDetail?.itemcategory?.name}</td>
              </tr>

              <tr>
                <td>Budget Quantity</td>
                <td>{itemDetail?.budgetquantitydetails?.budget_quantity}</td>
              </tr>
              <tr>
                <td>Currency</td>
                <td>{itemDetail?.budgetquantitydetails?.currency?.name}</td>
              </tr>
              <tr>
                <td>Actual Quantity</td>
                <td>{itemDetail?.budgetquantitydetails?.actual_quantity}</td>
              </tr>
              <tr>
                <td>Tag</td>
                <td>
                  {itemDetail?.tags === undefined ? "NA" : itemDetail?.tags}
                </td>
              </tr>
              {/* <tr>
                <td>Rfi No.</td>
                <td>
                  {itemDetail.rfi === undefined
                    ? "NA"
                    : itemDetail?.rfi?.rfidata?.rfi_number}
                </td>
              </tr>
              <tr>
                <td>Rfq No.</td>
                <td>
                  {itemDetail.rfq === undefined
                    ? "NA"
                    : itemDetail?.rfq?.rfqdata?.rfq_number}
                </td>
              </tr> */}
            </tbody>
          </table>
        </Box>
      </div>
    </div>
  );
};

export default SearchItemSchedulePage;
