/* eslint-disable no-unused-vars */
import React from "react";
import { GetAllActiveClientType, GetAllActiveCountry } from "../../../axios";
import { Button, FormDropDown, FormTextInput } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ClientForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createclientheader = Strings["ClientManagement.CreateClientHeader"],
    editclientheader = Strings["ClientManagement.EditClientHeader"],
    clientNameLabel = Strings["ClientManagement.CreateClientNameLable"],
    clientNameplaceholder =
      Strings["ClientManagement.CreateClientNamePlaceholder"],
    clientNameCustomError =
      Strings["ClientManagement.CreateClientNameCustomLineError"],
    clientTypeSelectText =
      Strings["ClientManagement.CreateClientTypeSelectText"],
    clientTypeLabel = Strings["ClientManagement.CreateClientTypeLable"],
    clientTypeCustomError =
      Strings["ClientManagement.CreateClientTypeCustomLineError"],
    clientAddressLabel = Strings["ClientManagement.CreateClientAddressLable"],
    clientAddressplaceholder =
      Strings["ClientManagement.CreateClientAddressPlaceholder"],
    clientAddressCustomError =
      Strings["ClientManagement.CreateClientAddressCustomLineError"],
    clientCityLabel = Strings["ClientManagement.CreateClientCityLable"],
    clientCityplaceholder =
      Strings["ClientManagement.CreateClientCityPlaceholder"],
    clientCityCustomError =
      Strings["ClientManagement.CreateClientCityCustomLineError"],
    clientCountrySelectText =
      Strings["ClientManagement.CreateClientCountrySelectText"],
    clientCountryLabel = Strings["ClientManagement.CreateClientCountryLable"],
    clientCountryCustomError =
      Strings["ClientManagement.CreateClientCountryCustomLineError"],
    clientPhoneLabel = Strings["ClientManagement.CreateClientPhoneLable"],
    clientPhoneplaceholder =
      Strings["ClientManagement.CreateClientPhonePlaceholder"],
    clientPhoneCustomError =
      Strings["ClientManagement.CreateClientPhoneCustomLineError"],
    clientFaxLabel = Strings["ClientManagement.CreateClientFaxLable"],
    clientFaxplaceholder =
      Strings["ClientManagement.CreateClientFaxPlaceholder"],
    clientFaxCustomError =
      Strings["ClientManagement.CreateClientFaxCustomLineError"],
    clientEmailLabel = Strings["ClientManagement.CreateClientEmailLable"],
    clientEmailplaceholder =
      Strings["ClientManagement.CreateClientEmailPlaceholder"],
    clientEmailCustomError =
      Strings["ClientManagement.CreateClientEmailCustomLineError"],
    clientWebsiteLabel = Strings["ClientManagement.CreateClientWebsiteLable"],
    clientWebsiteplaceholder =
      Strings["ClientManagement.CreateClientWebsitePlaceholder"],
    clientWebsiteCustomError =
      Strings["ClientManagement.CreateClientWebsiteCustomLineError"],
    cancelBtn = Strings["ClientManagement.createClientCancelBtn"],
    saveBtn = Strings["ClientManagement.createClientSaveBtn"],
    updateButton = Strings["ClientManagement.createClientUpdateBtn"];

  const [loading, setLoading] = React.useState(false);
  const [clientName, setClientName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });
  const [clientType, setClientType] = React.useState({
    value: initialValue.client_type_id ? initialValue.client_type_id : "",
    error: false,
    success: false,
  });

  const [clientAddress, setClientAddress] = React.useState({
    value: initialValue.address ? initialValue.address : "",
    error: false,
    success: false,
  });

  const [city, setCity] = React.useState({
    value: initialValue.city ? initialValue.city : "",
    error: false,
    success: false,
  });

  const [country, setCountry] = React.useState({
    value: initialValue.country_id ? initialValue.country_id : "",
    error: false,
    success: false,
  });

  const [phone, setPhone] = React.useState({
    value: initialValue.phone ? initialValue.phone : "",
    error: false,
    success: true,
  });

  const [fax, setFax] = React.useState({
    value: initialValue.fax ? initialValue.fax : "",
    error: false,
    success: false,
  });

  const [email, setEmail] = React.useState({
    value: initialValue.email ? initialValue.email : "",
    error: false,
    success: true,
  });

  const [website, setWebsite] = React.useState({
    value: initialValue.website ? initialValue.website : "",
    error: false,
    success: true,
  });
  const [countryDropdown, SetCountryDropdown] = React.useState([]);
  const [clientTypeDropdown, SetClientTypeDropdown] = React.useState([]);

  const handleChangeClientName = (value) => {
    setMessage(null);
    setClientName(value);
  };

  const handleChangeClientType = (value) => {
    setMessage(null);
    setClientType(value);
  };

  const handleChangeClientAddress = (value) => {
    setMessage(null);
    setClientAddress(value);
  };

  const handleChangeCity = (value) => {
    setMessage(null);
    setCity(value);
  };

  const handleChangeCountry = (value) => {
    setMessage(null);
    setCountry(value);
  };

  const handleChangePhone = (value) => {
    setMessage(null);
    setPhone(value);
  };

  const handleChangeFax = (value) => {
    setMessage(null);
    setFax(value);
  };

  const handleChangeEmail = (value) => {
    setMessage(null);
    setEmail(value);
  };

  const handleChangeWebsite = (value) => {
    setMessage(null);
    setWebsite(value);
  };
  const handleSaveData = () => {
    const formData = {
      name: clientName.value,
      client_type_id: clientType.value.toString(),
      address: clientAddress.value,
      city: city.value,
      country_id: country.value.toString(),
      phone: phone.value,
      fax: fax.value,
      email: email.value,
      website: website.value,
      profile_picture: null,
    };
    handleChangeFormData(formData);
  };

  let isValueChanged =
    clientName.value === initialValue.clientName &&
    clientType.value === initialValue.clientType &&
    clientAddress.value === initialValue.clientAddress &&
    city.value === initialValue.city &&
    country.value === initialValue.country &&
    phone.value === initialValue.value &&
    website.value === initialValue.value;

  let disable =
    clientName.error ||
    clientType.error ||
    clientAddress.error ||
    city.error ||
    country.error ||
    email.error ||
    phone.error ||
    fax.error ||
    website.error ||
    clientName.value === "" ||
    clientType.value === "" ||
    clientAddress.value === "" ||
    city.value === "" ||
    country.value === "" ||
    (clientName.success === false &&
      clientType.success === false &&
      clientAddress.success === false &&
      city.success === false &&
      country.success === false) ||
    isValueChanged;

  let isEditValueChanged =
    clientName.value === initialValue.clientName &&
    clientType.value === initialValue.clientType &&
    clientAddress.value === initialValue.clientAddress &&
    city.value === initialValue.city &&
    country.value === initialValue.country &&
    phone.value === initialValue.phone &&
    fax.value === initialValue.fax &&
    email.value === initialValue.email &&
    website.value === initialValue.website;

  let editDisable =
    clientName.error ||
    clientType.error ||
    clientAddress.error ||
    city.error ||
    country.error ||
    clientName.value === "" ||
    clientType.value === "" ||
    clientAddress.value === "" ||
    city.value === "" ||
    country.value === "" ||
    (clientName.success === false &&
      clientType.success === false &&
      email.success === false &&
      clientAddress.success === false &&
      city.success === false &&
      country.success === false &&
      phone.success === false &&
      fax.success === false &&
      website.success === false) ||
    isEditValueChanged;

  const getAllActiveCountry = () => {
    setLoading(true);
    GetAllActiveCountry()
      .then((res) => {
        setLoading(false);
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        SetCountryDropdown(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getAllActiveClientType = () => {
    setLoading(true);
    GetAllActiveClientType()
      .then((res) => {
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        SetClientTypeDropdown(result);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getAllActiveCountry();
    getAllActiveClientType();
  }, []);

  // console.log(clientEmailCustomError);
  return (
    <div className="modalFormContainer">
      {loading ? <LoaderComponent /> : null}
      <h2>{actionType === "add" ? createclientheader : editclientheader}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormTextInput
            maxLength={100}
            type="textarea"
            starProps={true}
            label={clientNameLabel}
            placeholder={clientNameplaceholder}
            Value={clientName.value}
            onChangeText={handleChangeClientName}
            Validate={true}
            CustomErrorLine={clientNameCustomError}
            editable
          />

          <FormDropDown
            labelStyle={{
              paddingBottom: "7px",
              marginTop: "12px",
            }}
            selectText={clientTypeSelectText}
            Label={clientTypeLabel}
            starProps={true}
            Options={clientTypeDropdown}
            onSelect={handleChangeClientType}
            customErrorLine={clientTypeCustomError}
            searchable={false}
            disable={false}
            value={clientType.value}
            isValidate={true}
          />

          <FormTextInput
            type="textarea"
            starProps={true}
            label={clientAddressLabel}
            placeholder={clientAddressplaceholder}
            Value={clientAddress.value}
            onChangeText={handleChangeClientAddress}
            Validate={true}
            CustomErrorLine={clientAddressCustomError}
            multiline={true}
            editable
          />

          <FormTextInput
            type="textarea"
            starProps={true}
            label={clientCityLabel}
            placeholder={clientCityplaceholder}
            Value={city.value}
            onChangeText={handleChangeCity}
            Validate={true}
            CustomErrorLine={clientCityCustomError}
            editable
          />
          <FormTextInput
            type="website"
            starProps={false}
            label={clientWebsiteLabel}
            placeholder={clientWebsiteplaceholder}
            Value={website.value}
            onChangeText={handleChangeWebsite}
            Validate={website.value && true}
            CustomErrorLine={clientWebsiteCustomError}
            editable
          />
          {/* <div className="row mt-4">
            <ChangeImageModal
              sendDataToParent={sendDataToParent}
              showHeader={false}
              imageResolution={[180, 180]}
              initialImage={image}
            />
          </div> */}
        </div>
        <div className="col-12 col-md-6 ps-0 ps-md-3 mt-2">
          <FormDropDown
            labelStyle={{
              paddingBottom: "10px",
            }}
            starProps={true}
            selectText={clientCountrySelectText}
            Label={clientCountryLabel}
            Options={countryDropdown}
            onSelect={handleChangeCountry}
            customErrorLine={clientCountryCustomError}
            searchable={false}
            disable={false}
            value={country.value}
            isValidate={true}
          />
          <div
            style={{
              marginTop: "-15px",
            }}
          >
            <FormTextInput
              type="phone"
              starProps={false}
              label={clientPhoneLabel}
              placeholder={clientPhoneplaceholder}
              Value={phone.value}
              onChangeText={handleChangePhone}
              Validate={phone.value && true}
              CustomErrorLine={clientPhoneCustomError}
              editable
              minLength={10}
              maxLength={14}
            />

            <FormTextInput
              type="phone"
              starProps={false}
              label={clientFaxLabel}
              placeholder={clientFaxplaceholder}
              Value={fax.value}
              onChangeText={handleChangeFax}
              // Validate={fax.value && true}
              CustomErrorLine={clientFaxCustomError}
              editable
            />

            <FormTextInput
              labelTextStyle={{
                paddingTop: "35px",
              }}
              // className="mt-5"
              type="email"
              label={clientEmailLabel}
              starProps={false}
              placeholder={clientEmailplaceholder}
              Value={email.value}
              onChangeText={handleChangeEmail}
              // Validate={email.value && true}
              CustomErrorLine={clientEmailCustomError}
              editable
            />
          </div>

          <div style={{ display: "flex", marginTop: "3rem" }}>
            <Button
              buttonName={actionType === "add" ? saveBtn : updateButton}
              buttonStyle={{ backgroundColor: colors.primary }}
              disable={actionType === "add" ? disable : editDisable}
              onClick={handleSaveData}
            />
            <Button
              buttonName={cancelBtn}
              buttonStyle={{
                backgroundColor: colors.darkgrey,
                marginLeft: "10px",
              }}
              onClick={() => {
                handleModalClose();
                setMessage(null);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientForm;
