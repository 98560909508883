import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import { logoRect } from "../../../assets";
import PropagateLoader from "react-spinners/PropagateLoader";
import moment from "moment";

const PaymentRequestDownload = (props) => {
  const { data,history } = props;
  const [show,setShow] = React.useState(false);

  // console.log("DATA",data)
  // console.log("HISTORY",history)

  React.useEffect(()=>{
    if(data){
      setShow(true);
    }
    
  },[data,history])
  const format=(val)=>{
    if(val == null) {
      return "0.00";
    }
    else {
      return parseFloat(val).toFixed(2);
    }
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "130px",
      height: "30px",
    },
    section: {
      margin: 10,
      padding: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid black",
      marginRight: "25px",
      marginLeft: "25px",
    },
    footer: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      marginLeft: "25px",
      marginRight: "25px",
      color: "black",
      display: "flex",
      flexDirection: "row",
    },
    // footer: {
    //     position: "absolute",
    //     fontSize: 12,
    //     bottom: 30,
    //     marginLeft: "25px",
    //     marginRight: "25px",
    //     color: "black",
    //     display: "flex",
    //     flexDirection: "row"
    // },
    pageNo: {
      position: 'absolute',
      bottom: 10,
      left: 0,
      right: 0,
      textAlign: 'center',
      fontSize: "10"
    },
  });

  return (
    <PDFViewer style={styles.viewer}>
    <>
     {show === true ?
      <Document >
        <Page size="A4" style={styles.page}>
          <View fixed>
            <View style={styles.section} fixed>
              <Image style={styles.image} src={logoRect} />
              <Text style={{ fontSize: "20px" }}>PAYMENT REQUEST</Text>
            </View>
          </View>
         
          <View>
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                marginTop: "7px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Project Name
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {data?.purchase_order?.purchase_order_item_details[0]
                    ?.budgetitem.budget.project.name
                    ? data?.purchase_order?.purchase_order_item_details[0]
                        ?.budgetitem.budget.project.name
                    : "N/A"}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Budget Number
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {data.purchase_order?.purchase_order_item_details[0]
                    ?.budgetitem.budget.serial_number +
                    " - " +
                    data.purchase_order?.purchase_order_item_details[0]
                      .budgetitem.budget?.name}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                marginTop: "9px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "100%",
                    fontSize: "9px",
                    padding: "3px",
                    backgroundColor: "#a4a4ce",
                    borderBottom: "1px solid black",
                  }}
                >
                  PO DETAILS
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Vendor
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {data.purchase_order.vendor.name}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  PO Number
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {data.purchase_order_serial_number
                    ? data.purchase_order_serial_number
                    : "N/A"}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  PO Value (AED)
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {(data.purchase_order.purchase_order_item_details.reduce(
                    (acc, curr) => {
                      return acc + parseFloat(curr.total_price);
                    },
                    0
                  )).toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "100%",
                    fontSize: "9px",
                    padding: "3px",
                    backgroundColor: "#a4a4ce",
                    borderBottom: "1px solid black",
                  }}
                >
                  PAYMENT HISTORY TO DATE
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Requested Not Yet Paid Amount (AED)
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {history && (history.length >= 1 ? (history.reduce(
                    (acc, curr) => {
                      if(curr.paid_amount == null){
                        return parseFloat(acc + parseInt(curr.total_amount)).toFixed(2);
                      }
                    },
                    0
                  )) : "0.00")}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Paid Amount (AED)
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {format(
                    history && (history.length >=1 ? history.reduce((acc,curr)=>{
                      if(curr.paid_amount != null){
                        return parseFloat(acc + parseInt(curr.total_amount));
                      }
                    },0):"0.00"))
                  }
                  {/* {(data.paid_amount == null || data.paid_amount == 0) ? "0.00" : (data.paid_amount).toFixed(2)} */}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Invoiced Amount (AED)
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {/* {data.total_amount} */}

                  {
                    (history && (history.length >=1 ? history.reduce((acc,curr)=>{
                      if(curr.paid_amount != null){
                        return parseFloat(acc + parseInt(curr.total_amount));
                      }
                    },0):parseFloat("0.00"))
                   + 
                    history && (history.length >=1 ?history.reduce(
                      (acc, curr) => {
                        if(curr.paid_amount == null){
                          return parseFloat(acc + parseInt(curr.total_amount));
                        }
                      },0): parseFloat("0.00")).toFixed(2))
                    }
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderTop: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  PO Balance To Be Paid (AED)
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {parseFloat(data.purchase_order.purchase_order_item_details.reduce(
                    (acc, curr) => {
                      return acc + parseFloat(curr.total_price);
                    },
                    0
                  ) - (data.paid_amount != null ? data.paid_amount: 0.00)).toFixed(2)}
                </Text>
              </View>
             
            </View>
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "100%",
                    fontSize: "8px",
                    padding: "3px",
                    backgroundColor: "#a4a4ce",
                    borderBottom: "1px solid black",
                  }}
                >
                  CURRENT PAYMENT
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Invoice Received Date
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {moment(data.received_on.split(" ")[0]).format("DD-MM-YYYY")}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Invoice Due Date
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {moment(data.due_on.split(" ")[0]).format("DD-MM-YYYY")}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Invoice #
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {data.invoice_serial_number}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Invoice Type
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {data.payment_progress.name
                    ? data.payment_progress.name
                    : "N/A"}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Invoice Value (AED)
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {data.total_amount >= 0 ? parseFloat(data.total_amount).toFixed(2) : "0.00"}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    padding: "3px",
                    backgroundColor: "#a4a4ce",
                    borderRight: "1px solid black",
                  }}
                >
                  TO BE INVOICED BALANCE (AED)
                </Text>
                <Text
                  style={{
                    width: "70%",
                    fontSize: "8px",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  {parseFloat(data.purchase_order.purchase_order_item_details.reduce(
                    (acc, curr) => {
                      return acc + parseFloat(curr.total_price);
                    },
                    0
                  ) - (( history && (history.length >=1 ? history.reduce((acc,curr)=>{
                    if(curr.paid_amount != null){
                      return parseFloat(acc + curr.total_amount);
                    }
                  },0):parseInt("0.00"))
                 + 
                  history && (history.length >=1 ?history.reduce(
                    (acc, curr) => {
                      if(curr.paid_amount == null){
                        return parseFloat(acc + curr.total_amount);
                      }
                    },0): parseInt("0.00")))+
                  
                  data.total_amount)).toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Payment Currency
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  AED
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Mode of Payment
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {data.mode_of_payment.name
                    ? data.mode_of_payment.name
                    : "N/A"}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Bank Details
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {data.bank_details ? data.bank_details : "N/A"}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Required Documents
                </Text>
                <Text style={{ width: "70%", fontSize: "8px", padding: "3px" }}>
                  {data.required_document.map((ele,index) => {
                     if(data.required_document.length > 1){
                      return `${ele.requireddocument.name + ((index == data.required_document.length - 1) ? "" :", ")}`;
                     }
                     if(data.required_document.length == 1){
                      return `${ele.requireddocument.name}`;
                     }
                    
                  })}
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={{
              marginTop: "5px",
              fontSize: "8px",
              marginLeft: "25px",
              marginRight: "25px",
            }}
          >
            Checked By Furnish
          </Text>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              border: "1px solid black",
              marginTop: "10px",
              borderRadius: "5px",
              marginRight: "25px",
              marginLeft: "25px",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                fontSize: "10px",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  width: "30%",
                  fontSize: "10px",
                  borderRight: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                Name
              </Text>
              <Text
                style={{
                  width: "40%",
                  fontSize: "10px",
                  padding: "5px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Signature
              </Text>
              <Text
                style={{
                  width: "30%",
                  fontSize: "10px",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                Date
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                fontSize: "10px",
                textAlign: "center",
              }}
            >
              <Text
                style={{
                  width: "30%",
                  height: "50px",
                  fontSize: "10px",
                  borderRight: "1px solid black",
                  padding: "5px",
                }}
              ></Text>
              <Text
                style={{
                  width: "40%",
                  height: "50px",
                  fontSize: "10px",
                  padding: "5px",
                  borderRight: "1px solid black",
                }}
              ></Text>
              <Text
                style={{
                  width: "30%",
                  height: "50px",
                  fontSize: "10px",
                  padding: "5px",
                }}
              ></Text>
            </View>
          </View>
          <Text
            style={{
              marginTop: "5px",
              fontSize: "8px",
              marginLeft: "25px",
              marginRight: "25px",
            }}
          >
            Authorized By Furnish
          </Text>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              border: "1px solid black",
              marginTop: "10px",
              borderRadius: "5px",
              marginRight: "25px",
              marginLeft: "25px",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                fontSize: "10px",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  width: "30%",
                  fontSize: "10px",
                  borderRight: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                Name
              </Text>
              <Text
                style={{
                  width: "40%",
                  fontSize: "10px",
                  padding: "5px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Signature
              </Text>
              <Text
                style={{
                  width: "30%",
                  fontSize: "10px",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                Date
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                fontSize: "10px",
                textAlign: "center",
              }}
            >
              <Text
                style={{
                  width: "30%",
                  height: "50px",
                  fontSize: "10px",
                  borderRight: "1px solid black",
                  padding: "5px",
                }}
              ></Text>
              <Text
                style={{
                  width: "40%",
                  height: "50px",
                  fontSize: "10px",
                  padding: "5px",
                  borderRight: "1px solid black",
                }}
              ></Text>
              <Text
                style={{
                  width: "30%",
                  height: "50px",
                  fontSize: "10px",
                  padding: "5px",
                }}
              ></Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              border: "1px solid black",
              marginTop: "10px",
              borderRadius: "5px",
              marginRight: "25px",
              marginLeft: "25px",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                fontSize: "10px",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  width: "30%",
                  fontSize: "10px",
                  borderRight: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                Name
              </Text>
              <Text
                style={{
                  width: "40%",
                  fontSize: "10px",
                  padding: "5px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Signature
              </Text>
              <Text
                style={{
                  width: "30%",
                  fontSize: "10px",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                Date
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                fontSize: "10px",
                textAlign: "center",
              }}
            >
              <Text
                style={{
                  width: "30%",
                  height: "50px",
                  fontSize: "10px",
                  borderRight: "1px solid black",
                  padding: "5px",
                }}
              ></Text>
              <Text
                style={{
                  width: "40%",
                  height: "50px",
                  fontSize: "10px",
                  padding: "5px",
                  borderRight: "1px solid black",
                }}
              ></Text>
              <Text
                style={{
                  width: "30%",
                  height: "50px",
                  fontSize: "10px",
                  padding: "5px",
                }}
              ></Text>
            </View>
          </View>
          <View style={styles.footer} fixed>
            <View>
              {/* <Text style={{ fontSize: "8px" }}>PO BOX 282247, Dubai, UAE</Text> */}
              {/* <Text style={{ fontSize: "8px" }}>tel +971 4 3307725</Text>
              <Text style={{ fontSize: "8px" }}>
                <Link
                  style={{ color: "black" }}
                  src="mailto:invitation@furnish-marketplace.com"
                >
                  invitation@furnish-marketplace.com
                </Link>
              </Text>
              <Text style={{ fontSize: "8px" }}>
                <Link
                  style={{ color: "black" }}
                  src="http://furnish-marketplace.com"
                >
                  furnish-marketplace.com
                </Link>
              </Text> */}
            </View>
            <View>
              
            </View>
          </View>
          </View>
          <Text
                style={styles.pageNo}
                render={({ pageNumber, totalPages }) =>
                  `Page # ${pageNumber} / ${totalPages}`
                }
                fixed
              />
        </Page>
      </Document> :
       <View style={{ paddingLeft: "15Px", marginLeft: "50%" }}>
       <PropagateLoader color="blue" />
        </View>
      }
      </>
     </PDFViewer>
  );
};

export default PaymentRequestDownload;
