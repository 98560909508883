/* eslint-disable no-unused-vars */
import React,{useEffect} from "react";
import * as XLSX from "xlsx";
import { Button } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const QuotationFileUpload = (props) => {
  const {
    errorMessage,
    handleChangeData = () => { },
    initialValue = {},
    handleModalClose,
    rfqId,
  } = props;

  const cancelButton = Strings["Quotation.cancellabel"],
    uploadButton = Strings["Quotation.uploadlabel"];

  const [selectedFile, setSelectedFile] = React.useState({
    value: initialValue.selectedFile ? initialValue.selectedFile : "",
    error: false,
    success: false,
  });
  const [uploadFile, setUploadFile] = React.useState([]);
  const [emptyFileAlert,setEmptyFileAlert] = React.useState(false);
  const [uploadFlag,setFlag]= React.useState(false)

  const handleChangeFile = (e) => {
   
    setSelectedFile(e.target.value);
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        // console.log("JSON",json)
        setUploadFile(json);
        setFlag(true)
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  useEffect(()=>{
    if(uploadFlag){
      if(uploadFile.length == 0){
        setEmptyFileAlert(true)
      }
      else{
        setEmptyFileAlert(false)
      }
    }
   
  },[emptyFileAlert,uploadFile])


  const handleSaveData = () => {
    const vendor = Object.keys(uploadFile[3]);
    const quotName = Object.values(uploadFile[0]);
    const currency = Object.values(uploadFile[uploadFile.length - 11]);
    const validTill = Object.values(uploadFile[1]);
    const leadTime = Object.values(uploadFile[uploadFile.length - 6]);
    const delivery = Object.values(uploadFile[uploadFile.length - 4]);
    const warrenty = Object.values(uploadFile[uploadFile.length - 3]);
    const paymentTerms = Object.values(uploadFile[uploadFile.length - 2]);
    const itemList = uploadFile.slice(5, uploadFile.length - 11);
    const fetchKey = Object.keys(itemList[0]);
    const newKeyss = fetchKey[5];
    const filterResult = itemList.map((ele) => {
      return {
        ...ele,
        unitPrice: ele[newKeyss],
        total: ele.__EMPTY_1 * ele[newKeyss]
      };
    });
    const newItemList = filterResult.map(
      ({
        "Request For Quotation": budget_item,
        unitPrice: unit_price,
        total: total_price,
      }) => ({
        budget_item,
        unit_price,
        total_price,
      })
    );
    const data = {
      rfq_id: rfqId,
      quotation_name: quotName[1],
      currency: currency[1],
      vendor: vendor[0],
      lead_time: leadTime[0],
      deliveryTerms: delivery[0],
      warrenty: warrenty[0],
      payment_info: paymentTerms[0],
      budget_item: newItemList,
      valid_till: validTill[3],
      cost_for_freight_installation: null,
      country: null,
      ship_to_vendor: null,
      cost_for_duties: null,
      shipping_info: null,
      tags: null,
      comment: null,
      installation_included: 0,
    };
    const selectedFile = { data: [data] };
    handleChangeData(selectedFile);
  };

  let disable = uploadFile.length === 0;

  return (
    <div className="modalFormContainer">
      <h2 className="mb-3" style={{ marginTop: "1.5rem" }}>{"Upload CSV File"}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      {emptyFileAlert && <h6 style={{ color: "red" }}>No Records Found To Upload</h6>}
      <input
        type="file"
        value={selectedFile.value}
        onChange={handleChangeFile}
        accept=".csv, .xlsx"
      />
      <div
        style={{
          display: "flex",
          marginTop: "1.5rem",
          justifyContent: "space-between",
        }}
      >
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey }}
          onClick={() => {
            handleModalClose();
          }}
        />
        <Button
          buttonName={uploadButton}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={disable}
          onClick={handleSaveData}
        />
      </div>
    </div>
  );
};

export default QuotationFileUpload;
