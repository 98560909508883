/* eslint-disable no-unused-vars */
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import { ImageCreation } from "../../../axios";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Button from "../../common/Button";

const ChangeImageModal = (props) => {
  const {
    showHeader = true,
    imageResolution = [200, 200],
    sendDataToParent,
    initialImage,
  } = props;

  const [Image, setImage] = useState(
    initialImage ? process.env.REACT_APP_BASE_URL2 + initialImage : ""
  );
  const [setUpdateImage] = useState("");
  const [isLoader, setisLoader] = React.useState(false);

  const uploadImage = async (e) => {
    const filename = e.target.files[0].name;
    var fileExtension = filename.split(".").pop();

    const files = e.target.files[0];
    const base64 = await convertBase64(files);
    var newbase64 = base64.replace(/^data:image\/[a-z]+;base64,/, "");
    setImage(base64);

    const formData = {
      file_data: newbase64,
      file_extension: fileExtension,
    };

    setisLoader(true);
    ImageCreation(formData)
      .then((res) => {
        // setUpdateImage(res.data.data.file_path);
        sendDataToParent(res.data.data.file_path);
        // console.log(res);
        setisLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setisLoader(false);
      });
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div>
      {showHeader && <h1>Profile Picture</h1>}
      {isLoader ? <LoaderComponent /> : null}
      <img
        src={Image}
        style={{
          width: imageResolution[0],
          height: imageResolution[1],
          margin: "auto",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
          border: "solid 2px #4747A1",
        }}
        alt=""
      />
      <div className="mt-4">
        <Button
          buttonName={!showHeader ? "" : "Change Picture"}
          onClick={() => {
            document.getElementById("fileInput").click();
          }}
          icon={<EditIcon />}
        />
        <Button
          buttonName={!showHeader ? "" : "Remove Picture"}
          buttonStyle={{ backgroundColor: colors.danger, marginLeft: "10px" }}
          onClick={() => {
            setImage("");
          }}
          icon={<DeleteIcon />}
        ></Button>
        <input
          type="file"
          id="fileInput"
          accept=".jpef, .png, .jpg"
          onChange={(e) => uploadImage(e)}
          hidden
        />
      </div>
    </div>
  );
};

export default ChangeImageModal;
