import React from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  ChangeRFQStatus,
  ChangeStatus,
  DeleteRequestForInformation,
  GetAllActiveRfi,
  GetAllActiveRfq,
  GetAssetByBudgetId,
  GetBudgetFile,
  GetPurchaseOrderData,
  UpdateRFQ,
  UpdateRfi,
} from "../../../axios";
import {
  AlertMessage,
  BreadCrumbs,
  BudgetFileTable,
  Modal,
  NodataFound,
  PurchaseOrderDocumentTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";

import {
  CreatePurchaseOrdersForm,
  RequestforInformationPageForm,
  RfiForm,
  RfqForm,
  SearchUploadDocuments,
} from "../../../forms/ProjectAndBudget";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import "./Document.css";
import { getItemFromStorage } from "../../../utils/storage";

const Documents = () => {
  const DraftTitle = Strings["Document.DraftTitle"],
    SendToVendorTitle = Strings["Document.SendToVendorTitle"],
    CancelledTitle = Strings["Document.CancelledTitle"],
    Name = Strings["Document.tableHead.Name"],
    Status = Strings["Document.tableHead.Status"],
    Date = Strings["Document.tableHead.Date"],
    TotalAmount = Strings["Document.tableHead.TotalAmount"];

  const location = useLocation();
  let proId = location.state
    ? location.state.proId
    : location.query
    ? JSON.parse(location.query.params).proId
    : null;

  let scope_id = location?.state ? location?.state?.scope_id : location?.query
    ? JSON.parse(location.query.params).scope_id
    : null;
  let budget_Name = location?.state ? location.state?.budget_name
    : location?.query ? JSON.parse(location.query.params).budget_name
    : null;

  
  const fetchId = useParams();
  const paramId = isDecoded(fetchId.id);
  const BudgtId = parseInt(paramId);
  let budget_id = location?.state ? location.state.budget_id : BudgtId;
 
  const [BudgetData, setBudgetData] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [alertBox, setAlertBox] = React.useState(false);
  const [rfiAlertBox, setRfiAlertBox] = React.useState(false);
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [setRfiArray] = React.useState([]);
  const [RfqId, setRfqId] = React.useState();
  const [RfiId] = React.useState();
  const params = useParams();
  const [rfqData, setRFQData] = React.useState([]);
  const [poData, setPOData] = React.useState([]);
  const [poDraft, setPoDraft] = React.useState([]);
  const [poClient, setPoClient] = React.useState([]);
  const [poVendorSign, setPoVendorSign] = React.useState([]);
  const [poClientSign, setPoClientSign] = React.useState([]);
  const [poVendor, setPoVendor] = React.useState([]);
  const [poCancelled, setPoCancelled] = React.useState([]);

  if(scope_id == null || scope_id == undefined && budget_Name == null || budget_Name == undefined && proId == null || proId == undefined && budget_id == null || budget_id == undefined){
    const paramData = JSON.parse(getItemFromStorage("PARAMS"))
    if(paramData){
        budget_id = paramData.budget_id;
        proId = paramData.proId;
        budget_Name = paramData.budget_Name;
        scope_id =  paramData.budget_scope;
    }
}
const encodeProId = isEncoded(proId);
const encodeBudId = isEncoded(budget_id);


  const paths = [
    {
      name: strings["Projects"],
      path: "project",
    },
    {
      name: strings["Budgets"],
      path: `projectbudget/${encodeProId}`,
    },
    {
      name: strings["Budget Items"],
      path: `budgetitem/${encodeBudId}`,
    },
    {
      name: strings["Documents"],
      path: "searchdocument",
    },
  ];
  const BudgetMapableData = [
    {
      dataKey: "id",
      title: "Id",
    },
    {
      dataKey: "name",
      title: "Name",
    },
    {
      dataKey: "filename",
      title: "Upload File",
    },
    {
      dataKey: "file",
      title: "File",
      isviewlink: true,
    },
  ];

  const mapableDataPO = [
    {
      dataKey: "purchase_order_serial_number",
      title: Name,
    },
    {
      dataKey: "purchase_order_status",
      title: Status,
    },
    {
      dataKey: "created_on",
      title: Date,
    },
    {
      dataKey: "total_price",
      title: TotalAmount,
    },
  ];

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const getBudgetFile = () => {
    GetBudgetFile(budget_id)
      .then((res) => {
        if (res.status === 200) {
          setBudgetData(res.data.data);
        }
      })
      .catch(() => {});
  };

  const getPurchaseOrderData = () => {
    GetPurchaseOrderData()
      .then((res) => {
        if (res.status === 200) {
          setPOData(res.data.data);
        }
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    getPurchaseOrderData();
    getBudgetFile();
  }, []);

  React.useEffect(() => {
    getRfi();
  }, []);

  const getDocuments = () => {
    GetAssetByBudgetId(budget_id)
      .then((res) => {
        const result = res.data.data[0].purchase_order_budget_item_details;

        const draftPo = result.filter((ele) => {
          return ele.purchase_order_status === "Draft";
        });
        const ClientPo = result.filter((ele) => {
          return ele.purchase_order_status === "Send To Client";
        });
        const signVendorPo = result.filter((ele) => {
          return ele.purchase_order_status === "Signed By Vendor";
        });

        const vendorPo = result.filter((ele) => {
          return ele.purchase_order_status === "Send To Vendor";
        });
        const signClientPo = result.filter((ele) => {
          return ele.purchase_order_status === "Signed By Client";
        });
        const cancelledPo = result.filter((ele) => {
          return ele.purchase_order_status === "Cancelled";
        });

        setPoDraft(draftPo);
        setPoClient(ClientPo);
        setPoVendorSign(signVendorPo);
        setPoClientSign(signClientPo);
        setPoVendor(vendorPo);
        setPoCancelled(cancelledPo);
      })
      .catch(() => {});
  };
  React.useEffect(() => {
    getDocuments();
  }, []);

  const getRfi = () => {
    setisLoader(true);
    GetAllActiveRfi(budget_id)
      .then((res) => {
        setisLoader(false);
        const result = res.data.data.map((elm) => ({
          id: elm.id,
          name: elm.name,
          description: elm.description,
          selectedFile: elm.files,
          budget_item: elm.budget_item,
        }));
        for (let i of result) {
          let selectedFile = [];
          for (let j of i.selectedFile) {
            selectedFile.push(j.file_path);
          }
          i.selectedFile = selectedFile.toString();
        }
        setRfiArray(result);
      })
      .catch(() => {
        setisLoader(false);
      });
  };

  const getAllRfq = () => {
    setisLoader(true);
    GetAllActiveRfq(budget_id)
      .then((res) => {
        setisLoader(false);
        const result = res.data.data.map((elm) => ({
          id: elm.id,
          rfqName: elm.name,
          deadline: elm.budget.budget_end_date.split(" ")[0],
          country: elm.countries,
          incoterm: elm.incoterm,
          modeOfTransportation: elm.modeOfTransportation,
          tendorNotes: elm.tendor_notes,
          importantNotes: elm.important_notes,
          vendor: elm.vendor,
          budget_item: elm.budget_item,
          IssuingDt: elm.budget.budget_start_date,
          budgetNo: elm.budget.serial_number,
        }));
        setRFQData(result);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getAllRfq();
  }, []);

  const updateRFQ = (formData) => {
    setisLoader(true);
    UpdateRFQ(formData, RfqId)
      .then((res) => {
        getAllRfq();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const updateRfi = (formData) => {
    setisLoader(true);
    UpdateRfi(formData, RfiId)
      .then((res) => {
        getRfi();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    if (clickedBtn === "addrfq") {
      return (
        <RfqForm
          setMessage={setMessage}
          errorMessage={errorMessage}
          budget_id={params}
          handleChangeData={(val) => {}}
          actionType={clickedBtn}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "addrfi") {
      return (
        <RequestforInformationPageForm
          setMessage={setMessage}
          errorMessage={errorMessage}
          handleChangeData={(val) => {}}
          actionType={clickedBtn}
          initialValue={currentType}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "edit") {
      return (
        <RfiForm
          setMessage={setMessage}
          errorMessage={errorMessage}
          budget_id={params}
          handleUpdateData={updateRfi}
          initialValue={currentType}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "editPO") {
      return (
        <CreatePurchaseOrdersForm
          handleChangeData={(val) => {}}
          actionType={clickedBtn}
          initialValue={currentType}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "editRFQ") {
      return (
        <RfqForm
          setMessage={setMessage}
          errorMessage={errorMessage}
          budget_id={params}
          handleChangeData={updateRFQ}
          actionType={clickedBtn}
          initialValue={currentType}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "uploadDoc") {
      return (
        <SearchUploadDocuments
          setMessage={setMessage}
          errorMessage={errorMessage}
          budget_id={params}
          handleChangeData={updateRFQ}
          actionType={clickedBtn}
          initialValue={currentType}
          handleModalClose={handleModalClose}
        />
      );
    }
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setAlertBox(false);
    setisLoader(true);
    ChangeRFQStatus(RfqId)
      .then((res) => {
        getAllRfq();
        setisLoader(false);
      })
      .catch(() => {
        setisLoader(false);
      });
  };

  const handleChangeSucessRfi = () => {
    setRfiAlertBox(false);
    DeleteRequestForInformation(RfiId);
    setisLoader(true);

    ChangeStatus(RfiId)
      .then(() => {
        getRfi();
        setisLoader(false);
      })
      .catch(() => {
        setisLoader(false);
      });
  };

  const handleChangeEditPO = (id) => {
    const resultPO = poData.find((res) => {
      return res.id === id;
    });
    setCurrentType(resultPO);
    openAddModal();
    handleSelectBtn("editPO");
  };

  const handleChangeDelete = (id) => {
    setAlertBox(true);
    const resultRFQ = rfqData.find((res) => {
      return res.id === id;
    });
    setRfqId(resultRFQ.id);
  };
  // console.log(poDraft);
  const handleView = () => {};
  return (
    <div className="content-wrapper p-0">
      <Helmet>
        <title>Furnish | Documents</title>
      </Helmet>
      {isLoader ? <LoaderComponent /> : null}
      <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </Modal>
      <div className="row mt-4">
        <h3 className=""> {"Documents"} </h3>
        <BreadCrumbs
          pathList={paths}
          obj={{
            proId: proId,
            budget_id: budget_id,
            budget_name: budget_Name,
            scope_id: scope_id
          }}
        />
        {alertBox && (
          <AlertMessage
            title={"Confirmation"}
            message={"Do you wants to delete ?"}
            onChangeCancel={handleChangeCancel}
            onChangeSucess={handleChangeSuccess}
          />
        )}
        {rfiAlertBox && (
          <AlertMessage
            title={"Confirmation"}
            message={"Do you wants to delete ?"}
            onChangeCancel={handleChangeCancel}
            onChangeSucess={handleChangeSucessRfi}
          />
        )}
      </div>

      <div className="DocumentbudgetTable ">
        <h4 style={{ marginTop: "10px" }}>{DraftTitle}</h4>
        {poDraft.length > 0 ? (
          <PurchaseOrderDocumentTable
            data={poDraft}
            mapableData={mapableDataPO}
            searchProp={["purchase_order_serial_number"]}
            handleChangeDelete={handleChangeDelete}
            handleChangeEdit={handleChangeEditPO}
          />
        ) : (
          <>
            <NodataFound norecord={false} />
          </>
        )}
      </div>

      <div className="DocumentbudgetTable">
        <h4 style={{ marginTop: "10px" }}>{"Send To Client"}</h4>
        {poClient.length > 0 ? (
          <PurchaseOrderDocumentTable
            data={poClient}
            mapableData={mapableDataPO}
            searchProp={["purchase_order_serial_number"]}
          />
        ) : (
          <>
            {isLoader ? <LoaderComponent /> : <NodataFound norecord={false} />}
          </>
        )}
      </div>
      <div className="DocumentbudgetTable">
        <h4 style={{ marginTop: "10px" }}>{"Signed By Client"}</h4>
        {poClientSign.length > 0 ? (
          <PurchaseOrderDocumentTable
            data={poClientSign}
            mapableData={mapableDataPO}
            searchProp={["purchase_order_serial_number"]}
          />
        ) : (
          <>
            <NodataFound norecord={false} />
          </>
        )}
      </div>

      <div className="DocumentbudgetTable">
        <h4 style={{ marginTop: "10px" }}>{SendToVendorTitle}</h4>
        {poVendor.length > 0 ? (
          <PurchaseOrderDocumentTable
            data={poVendor}
            mapableData={mapableDataPO}
            searchProp={["purchase_order_serial_number"]}
          />
        ) : (
          <>
            {isLoader ? <LoaderComponent /> : <NodataFound norecord={false} />}
          </>
        )}
      </div>
      <div className="DocumentbudgetTable">
        <h4 style={{ marginTop: "10px" }}>{"Signed By Vendor"}</h4>
        {poVendorSign.length > 0 ? (
          <PurchaseOrderDocumentTable
            data={poVendorSign}
            mapableData={mapableDataPO}
            searchProp={["purchase_order_serial_number"]}
          />
        ) : (
          <>
            <NodataFound norecord={false} />
          </>
        )}
      </div>

      <div className="DocumentbudgetTable">
        <h4 style={{ marginTop: "10px" }}>{CancelledTitle}</h4>
        {poCancelled.length > 0 ? (
          <PurchaseOrderDocumentTable
            data={poCancelled}
            mapableData={mapableDataPO}
            searchProp={["purchase_order_serial_number"]}
          />
        ) : (
          <>
            <NodataFound norecord={false} />
          </>
        )}
      </div>

      <div className="DocumentbudgetTable">
        <h4 style={{ marginTop: "10px" }}>{"Budget Files"}</h4>
        {BudgetData.length > 0 ? (
          <BudgetFileTable
            placeholder={"name"}
            data={BudgetData}
            mapableData={BudgetMapableData}
            searchProp={["name"]}
            handleView={handleView}
          />
        ) : (
          <>
            <NodataFound norecord={false} />
          </>
        )}
      </div>
    </div>
  );
};

export default Documents;
