import React from "react";
import { ImageCreation, DeletePurchaseOrderFileData } from "../../../axios";
import { AlertMessage, Button } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import { AiOutlineDownload, AiOutlineClose } from "react-icons/ai";
import {
  Modal as ExistModal,
  IconButton,
  Button as ButtonComponent,
} from "../../../components";
import { Tooltip } from "@mui/material";
import { Delete, Download } from "@mui/icons-material";
import { CircularProgress } from "@material-ui/core";

const PurchaseOrderFileUpload = (props) => {
  const {
    errorMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const cancelButton = Strings["Country.CreateCountryCancelButton"],
    saveButton = Strings["Country.CreateCountrySaveButton"],
    updateBtn = Strings["Country.CreateCountryUpdateButton"];

  const [fileId, setFileID] = React.useState();
  const [alertBox, setAlertBox] = React.useState(false);

  const [uploadedFiles, setUploadedFiles] = React.useState(
    Array.isArray(initialValue) ? initialValue : []
  );

  React.useEffect(() => {
    if (Array.isArray(initialValue)) {
      setUploadedFiles(initialValue);
    }
    setIsLoading(false);
  }, [initialValue]);

  const [uid, setUid] = React.useState(null);

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUid(user.id);
  }, []);

  // console.log({ uploadedFiles });

  const [isLoader, setIsLoader] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = (error) => reject(error);
    });
  };

  const handleChangeFile = async (data) => {
    setIsLoader(true);
    if (data.target.files.length > 0) {
      const filename = data.target.files[0].name;
      const fileExtension = filename.split(".").pop();
      const file = data.target.files[0];
      const base64 = await convertBase64(file);

      const uploadData = {
        file_data: base64.split("base64,")[1],
        file_extension: fileExtension,
      };

      await ImageCreation(uploadData)
        .then((res) => {
          setIsLoader(false);
          const newFile = {
            id: res.data.data.id,
            filename,
            filePath: res.data.data.file_path,
          };
          setUploadedFiles((prev) => [...prev, newFile]);
          setFileID(res.data.data.id);
        })
        .catch(() => setIsLoader(false));
    }
  };

  const handleDownloadFile = (filePath, filename) => {
    const link = document.createElement("a");
    link.href = filePath;
    link.download = filename;
    link.click();
  };

  const [deleteId, setDeleteId] = React.useState();

  const handleDeleteFile = (fileId) => {
    setDeleteId(fileId);
    setAlertBox(!alertBox);
  };

  const handleDeleteSucess = () => {
    setIsLoading(true);
    DeletePurchaseOrderFileData(deleteId)
      .then((res) => {
        if (res.data && res.data.success) {
          setUploadedFiles((prevFiles) =>
            prevFiles.filter((file) => file.id !== fileId)
          );
        }
        setAlertBox(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting file:", error);
        setIsLoading(false);
      });
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  const handleSaveData = () => {
    const formData = {
      file_data: uploadedFiles.map((file) => ({
        file_path: file.filePath,
        filename: file.filename,
      })),
      action_by: uid,
    };

    handleChangeData(formData);
  };

  return (
    <div className="modalFormContainer">
      {isLoader && <LoaderComponent />}
      <h2 className="mb-3">{"Upload File"}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}

      <div
        className="row d-flex justify-content-between align-items-center"
        style={{ marginTop: "1.5rem" }}
      >
        <div className="col-sm-8">
          <input
            type="file"
            onChange={handleChangeFile}
            accept=".csv, .xlsx, .pdf"
          />
        </div>
      </div>

      {/* Display already uploaded files */}
      <div style={{ marginTop: "1.5rem" }}>
        <h4>Uploaded Files</h4>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : uploadedFiles.length > 0 ? (
          uploadedFiles.map((file) => {
            const fileExtension =
              file?.filename && typeof file.filename === "string"
                ? file.filename.split(".").pop().toLowerCase()
                : "";
            return (
              <div
                key={file.id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {fileExtension === "csv" && (
                    <FaFileCsv
                      style={{ color: "#8BC34A", marginRight: "8px" }}
                    />
                  )}
                  {fileExtension === "pdf" && (
                    <FaFilePdf style={{ color: "red", marginRight: "8px" }} />
                  )}
                  {fileExtension === "xlsx" && (
                    <FaFileExcel
                      style={{ color: "#43A047", marginRight: "8px" }}
                    />
                  )}
                  <span>{file.filename}</span>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <span className="addButton pt-1">
                    <IconButton>
                      <Tooltip title="Download">
                        <Download
                          style={{
                            fontSize: "20px",
                            margin: "5px 5px ",
                            cursor: "pointer",
                            color: colors.primary,
                          }}
                          onClick={() =>
                            handleDownloadFile(file.file_path, file.filename)
                          }
                        />
                      </Tooltip>
                    </IconButton>
                  </span>

                  <span className="addButton pt-1">
                    <IconButton>
                      <Tooltip title="Delete">
                        <Delete
                          style={{
                            fontSize: "20px",
                            margin: "5px 5px ",
                            cursor: "pointer",
                            color: "red",
                          }}
                          onClick={() => handleDeleteFile(file.id)}
                        />
                      </Tooltip>
                    </IconButton>
                  </span>
                  {alertBox && (
                    <AlertMessage
                      title="Confirmation"
                      message="Do you want to delete the file ?"
                      onChangeCancel={handleChangeCancel}
                      onChangeSucess={handleDeleteSucess}
                    />
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <p>No files uploaded yet.</p>
        )}
      </div>

      {/* Action Buttons */}
      <div
        style={{
          display: "flex",
          marginTop: "1.5rem",
          justifyContent: "space-between",
        }}
      >
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey }}
          onClick={handleModalClose}
        />
        <Button
          buttonName={actionType === "add" ? saveButton : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          onClick={handleSaveData}
          disable={uploadedFiles.length === 0}
        />
      </div>
    </div>
  );
};

export default PurchaseOrderFileUpload;
